import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import clsx from 'clsx'

import useDateFns from '@hooks/useDateFns'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
// TODO: use input instead of button
// import PostInputField from '@objects/formFields/postInput'
import CreateContentButton from '@objects/button/createContentButton'
import LikeButton from '@objects/button/like'
import Button from '@objects/button'
import Topic from '@objects/topic'
import { COMMENT_SUBMIT_SUCCESS, eventBus } from '@services/eventBus'

import CardMetaBar from './cardMetaBar'
import CardHeader from './cardHeader'
import DeletedElement from './deletedElement'
import { Link } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  tagsWrapper: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(8),
  },
  headline: {
    ...theme.typography.h5,
  },
  wrappedHeadline: {
    ...theme.typography.h5,
    lineHeight: '23px',
  },
  teaserCopy: {
    ...theme.typography.teaser,
    marginBottom: theme.spacing(4),
  },
  tag: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
  },
  metaBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  link: {
    ...theme.typography.link,
  },
  headlineLink: {
    display: 'flex',
    width: 'fit-content',
    '&:hover': {
      ...theme.typography.link,
    },
  },
  readmore: {
    fontSize: '14px',
  },

  darkBackground: {},
  inputSeparator: {
    border: `1px solid ${theme.palette.grey.light}`,
    margin: theme.spacing(8, 0, 5, 0),
  },
  commentContainer: {
    padding: theme.spacing(0, 3),
    position: 'relative',
    height: 'auto',
    maxHeight: 0,
    overflow: 'hidden',
    opacity: 0,
    transition:
      'max-height 0.3s 0.3s ease, opacity 0.3s ease, margin-bottom 0.3s ease',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 7),
    },
    '&$open': {
      paddingTop: theme.spacing(5),
      marginBottom: theme.spacing(0),
      opacity: 1,
      overflow: 'visible',
      maxHeight: '1000px',
      transition: 'max-height 0.3s ease, opacity 0.3s 0.3s ease',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5, 7, 0, 7),
      },
    },
    '& $darkBackground': {
      position: 'relative',
      zIndex: 0,
      '&:before': {
        content: '""',
        zIndex: -1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: theme.spacing(-7),
        right: theme.spacing(-7),
        background: theme.palette.background.grey,
        [theme.breakpoints.up('md')]: {
          left: theme.spacing(-15),
          right: theme.spacing(-15),
        },
      },
    },
  },
  open: {},
  buttonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(10),
    '& button': {
      fontSize: '18px',
      margin: 'auto',
    },
  },
  comment: {
    padding: theme.spacing(8, 0),
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
  hasBeenEdited: {
    marginTop: theme.spacing(3),
    color: '#4b4b4b',
    fontSize: '14px',
  },
}))

function CardContent({
  commentsCallback,
  commentState,
  setCommentState,
  commentsRef,
  replyCount,
  likeCount,
  userName,
  userColor,
  timestamp,
  isExpert,
  isEdited,
  editReason,
  lastEditedTimestamp,
  topics,
  title,
  text,
  id,
  interactive,
  type,
  comments,
  isWrapped,
  noMetaBar,
  textLink,
  baseLevel,
}) {
  const classes = useStyles()

  const { formatDate } = useDateFns()

  const headlineBaseLevel = !!baseLevel ? baseLevel : 2

  let readPostId = 'press.read.more'
  if (isWrapped) {
    readPostId = 'postcard.read.question'
    if (type === 'dialogue-answer') {
      readPostId = 'postcard.read.answer'
    }
  }

  function renderReadPost() {
    if (textLink) {
      return (
        <Link className={clsx(classes.link, classes.readmore)} to={textLink}>
          <FormattedMessage id={readPostId} /> &gt;
        </Link>
      )
    }
    return (
      <span className={clsx(classes.link, classes.readmore)}>
        <FormattedMessage id={readPostId} /> &gt;
      </span>
    )
  }

  function renderHeadline() {
    if (textLink) {
      return (
        <Link
          className={clsx(classes.headlineLink, classes.readmore)}
          to={textLink}
        >
          <Headline
            className={clsx({ [classes.wrappedHeadline]: isWrapped })}
            level={
              'dialogue-answer' === type || 'answer' === type
                ? headlineBaseLevel + 2
                : headlineBaseLevel + 1
            }
          >
            {title}
          </Headline>
        </Link>
      )
    }
    return (
      <Headline
        className={clsx(
          { [classes.wrappedHeadline]: isWrapped },
          classes.headline
        )}
        level={
          'dialogue-answer' === type || 'answer' === type
            ? headlineBaseLevel + 2
            : headlineBaseLevel + 1
        }
      >
        {title}
      </Headline>
    )
  }

  return (
    <>
      <CardHeader
        id={`postcard-${type}-${id}`}
        userName={userName}
        userColor={userColor}
        timestamp={timestamp}
        isExpert={isExpert}
        isWrapped={isWrapped}
        hideTimestamp={type === 'dialogue-answer'}
      />
      <div>
        {renderHeadline()}
        {'dialogue-full' === type || 'answer' === type ? (
          <>
            <Copy html={text} component={'div'} type={'post'} />
            {isEdited && (
              <div className={classes.hasBeenEdited}>
                {!!lastEditedTimestamp && (
                  <span>
                    <FormattedMessage id={'dialogue.hasBeenEdited'} />{' '}
                    {formatDate(lastEditedTimestamp, 'dd.MM.yy')}
                  </span>
                )}
                {!!editReason && (
                  <span>
                    ,{' '}
                    <Typography component="q" className={classes.hasBeenEdited}>
                      {editReason}
                    </Typography>
                  </span>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Copy
              className={classes.teaserCopy}
              html={text}
              truncate={64}
              component={'div'}
              type={'post'}
            />

            {renderReadPost()}
          </>
        )}
        {!isWrapped && topics && topics.length > 0 && (
          <div className={classes.tagsWrapper}>
            {topics.map((topic) => {
              return (
                <Topic className={classes.tag} key={_.uniqueId('tag-')}>
                  {topic}
                </Topic>
              )
            })}
          </div>
        )}
      </div>
      {!noMetaBar && (
        <CardMetaBar
          postId={id}
          interactive={interactive}
          type={type}
          likeCount={likeCount}
          commentsCallback={commentsCallback}
          replyCount={replyCount}
          textLink={textLink}
        />
      )}
      {'answer' === type && (
        <>
          <hr className={classes.inputSeparator} />
          <CreateContentButton
            color={'outlineGrey'}
            icon="Pen"
            fullwidth
            contentType="createComment"
            answerId={id}
            onSuccessCallback={(data) => {
              eventBus.dispatch(COMMENT_SUBMIT_SUCCESS, data)
            }}
          >
            <FormattedMessage id={'dialogue.createComment'} />
          </CreateContentButton>
          {!!comments && comments.length > 0 && (
            <div
              className={clsx(classes.commentContainer, {
                [classes.open]: commentState.open,
              })}
              style={commentState.openStyle}
              ref={commentsRef}
            >
              {comments.map((comment) => {
                return (
                  <div
                    key={_.uniqueId('comment-')}
                    className={clsx(classes.comment, {
                      [classes.darkBackground]: comment.isExpertComment,
                    })}
                  >
                    {comment.isDeleted ? (
                      <DeletedElement
                        type={'comment'}
                        deleteType={comment.deleteType}
                        userName={comment.userName}
                        userColor={comment.userColor}
                        timestamp={comment.timestamp}
                      />
                    ) : (
                      <>
                        <CardHeader
                          id={`postcard-comment-${comment.id}`}
                          userName={comment.userName}
                          userColor={comment?.userColor ?? 2}
                          timestamp={comment.timestamp}
                          isExpert={comment.isExpertComment}
                          isComment
                        />
                        <Copy
                          html={comment.text}
                          component={'div'}
                          type="post"
                        />
                        {isEdited && (
                          <div className={classes.hasBeenEdited}>
                            {!!comment.lastEditedTimestamp && (
                              <span>
                                <FormattedMessage
                                  id={'dialogue.hasBeenEdited'}
                                />
                                {formatDate(
                                  comment.lastEditedTimestamp,
                                  'dd.MM.yy'
                                )}
                              </span>
                            )}
                            {!!comment.editReason && (
                              <span>
                                ,{' '}
                                <Typography
                                  component="q"
                                  className={classes.hasBeenEdited}
                                >
                                  {comment.editReason}
                                </Typography>
                              </span>
                            )}
                          </div>
                        )}

                        <div className={classes.metaBar}>
                          <div />
                          <div
                            className={clsx(classes.likes, {
                              red: 'dialogue' === type,
                            })}
                          >
                            <div className={classes.likeComment}>
                              <LikeButton
                                type={'comment'}
                                targetId={comment.id}
                                likeCount={comment.likeCount}
                                interactive={interactive}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
              <div className={classes.buttonContainer}>
                <Button
                  color={'outline'}
                  onClick={() => {
                    setCommentState((commentState) => ({
                      ...commentState,
                      open: false,
                    }))
                  }}
                  type={'secondary'}
                >
                  <FormattedMessage id={'dialogue.post.closeComments'} />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

CardContent.propTypes = {
  commentsCallback: PropTypes.func,
  commentState: PropTypes.object,
  setCommentState: PropTypes.func,
  commentsRef: PropTypes.object,
  id: PropTypes.number,
  replyCount: PropTypes.number,
  likeCount: PropTypes.number,
  userName: PropTypes.string,
  userColor: PropTypes.number,
  topics: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  text: PropTypes.string,
  textLink: PropTypes.string,
  timestamp: PropTypes.string,
  comments: PropTypes.array,
  isExpert: PropTypes.bool,
  isWrapped: PropTypes.bool,
  noMetaBar: PropTypes.bool,
  type: PropTypes.oneOf([
    'default',
    'dialogue',
    'dialogue-full',
    'answer',
    'dialogue-answer',
  ]),
  interactive: PropTypes.bool,
}

export default CardContent
